<template>
  <div>
    <div id="helpPage">
      <div style="font-size: 0">
        <img
          class="helpPageImg"
          src="https://activity-resource.321mh.com/nft/notice/tianzun_help.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="footer">
        <div class="helpBtn" @click="helpHandle">给Ta助力</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { nftUtils } from '@/utils';
import { useRoute, useRouter } from 'vue-router';
import { gavehelp } from '@/service/home';
export default {
  name: 'helpPage',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      collections_id: route.query.cid,
      my_id: route.query.mid
    });
    onMounted(() => {});

    const helpHandle = () => {
      gavehelp({
        collections_id: state.collections_id,
        my_id: state.my_id
      }).then(res => {
        console.log(res);

        if (res.status == 0) {
          toastFn({ message: '助力成功', duration: 2000 });
        } else {
          toastFn({ message: res.message, duration: 2000 });
        }
        router.push({ path: '/home' });
      });
    };

    return {
      ...toRefs(state),
      helpHandle
    };
  }
};
</script>

<style lang="scss" scoped>
#helpPage {
  // background: pink;
  .helpPageImg {
    width: 100%;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);

  .helpBtn {
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
    width: 272px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #1c172a;
    background-image: url('../imgs/comm/pic_button_cmh.png');
    background-size: 100%;
  }
}
</style>
